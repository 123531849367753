// General Style
html {
  @media(max-width: 767px) {
    font-size: 14px; } }
body {
  font-family: 'Heebo', sans-serif;
  color: #7E7E7E;
  position: relative; }

a {
  transition: 0.3s;
  outline: none; }

img {
  max-width: 100%; }

.main {
  padding-bottom: 20px; }

.page-title {
  font-weight: 300;
  color: #D30000;
  font-size: 35px;
  letter-spacing: 0.05em;
  @media (max-width: 767px) {
    font-size: 30px; } }

.bob-4 {
  margin: 0 0 35px;
  padding-bottom: 35px;
  border-bottom: 4px solid rgba(239, 239, 239, 0.5); }

.page-subtitle {
  font-weight: 300;
  color: #B2B2B2;
  font-size: 18px;
  margin: 0 0 35px;
  line-height: 1.5;
  letter-spacing: 0.09em;
  @media (max-width: 767px) {
    font-size: 16px; } }

.color-hover {
  color: #E8BE70 !important; }

.color-red {
  color: #D30000 !important; }

.text-height {
  line-height: 1.67; }

.container {
  @media (min-width: 1280px) {
    max-width: 1200px; }
  @media (max-width: 1199px) {
    max-width: none;
    width: 100%; } }

.small-container {
  padding: 0 20px;
  @media (max-width: 767px) {
    padding: 0 !important; } }

.btn-outline {
  border: none;
  box-shadow: inset 0px 0px 0px 1px #D30000;
  color: #D30000;
  width: 194px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-size: 13px;
  text-decoration: none !important;
  transition: 0.3s ease-in-out;
  position: relative;
  overflow: hidden;

  > span {
    position: relative;
    z-index: 1; }

  &:before {
    content: '';
    width: 100%;
    height: 0;
    background: rgb(246,233,184);
    background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out; }

  &:hover {
    box-shadow: none;
    color: #ffffff;

    &:before {
      height: 100%; } }

  i {
    margin-left: 5px;
    font-size: 12px;
    margin-top: 2px; } }

.btn-gradient {
  border: none;
  background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
  border: none;
  color: #ffffff;
  width: 194px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 33px;
  font-size: 13px;
  text-decoration: none !important;
  position: relative;
  overflow: hidden;

  &:hover {
    color: #ffffff; }

  > span {
    position: relative;
    z-index: 1; }
  i {
    margin-left: 5px;
    font-size: 12px;
    margin-top: 2px; } }

.background-fixed {
  height: 500px;
  position: relative;
  z-index: -1;
  @media (max-width: 767px) {
    height: 350px; }

  &-inner {
    position: fixed;
    height: 500px;
    overflow: hidden;
    width: 100%;
    @media (max-width: 767px) {
      height: 350px; } }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.content-html {
  padding: 50px 0 60px;
  margin-bottom: -50px;
  background-color: #fff;
  @media (max-width: 767px) {
    padding-top: 30px; }

  h1, h2, h3, h4, h5 {
    color: #D30000;
    font-weight: 300; } }

.content-wrapper {
  padding: 50px 0 60px;
  margin-bottom: -50px;
  background-color: #fff;
  @media (max-width: 767px) {
    padding-top: 30px; } }

.content-detail {
  padding: 120px 0 60px;
  margin-bottom: -50px;
  background-color: #fff;
  @media (max-width: 767px) {
    padding-top: 100px; } }

.history-years {
  padding-top: 40px;
  @media (max-width: 767px) {
    padding-top: 0; }

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      display: flex;
      padding-bottom: 40px;
      margin: 0;
      position: relative;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        padding-bottom: 0; }

      &:before {
        content: '';
        width: 5px;
        height: 100%;
        position: absolute;
        background: rgb(204,157,51);
        background: linear-gradient(180deg, rgba(204,157,51,1) 0%, rgba(246,233,184,1) 33%, rgba(232,190,112,1) 66%, rgba(204,157,51,1) 100%,);
        left: 127px;
        top: 0;
        @media (max-width: 767px) {
          display: none; } }

      &:last-child {
        &::before {
          display: none; } }

      .years {
        font-size: 30px;
        font-weight: 900;
        color:#B70D1C {}
        @media (max-width: 767px) {
          font-size: 25px; } }

      .image-separator {
        width: 120px;
        flex-shrink: 0;
        margin-top: -10px;
        margin-left: -5px;
        z-index: 1;
        position: relative;
        @media (max-width: 767px) {
          display: none; }

        &:before {
          content: '';
          width: 38px;
          height: 38px;
          border-radius: 50%;
          background-color: #e8be70;
          position: absolute;
          transition: 0.3s;
          top: 8.5px;
          left: 47px;
          opacity: 0;
          visibility: hidden; }


        img {
          width: 100%; } }

      > ul {
        padding: 0;
        list-style: none;
        margin: 0;
        @media (max-width: 767px) {
          padding-left: 20px; }

        li {
          position: relative;
          margin-bottom: 20px;
          padding-left: 15px;
          margin-top: 5px;

          &:before {
            content: '';
            width: 9px;
            height: 9px;
            background: image('homepage/list-style.png') no-repeat;
            background-size: contain;
            position: absolute;
            left: -3px;
            top: 8px; } } }

      &:hover {
        .image-separator {
          &:before {
            opacity: 1;
            visibility: visible; } } } } } }

.card-category {
  border-radius: 32px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-out;
      // @media (max-width: 991px)
 } }      //   transform: scale(1.1)

  .desc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    z-index: 2;

    .title {
      font-size: 24px;
      font-weight: 700;
      transition: 0.3s ease-out;
      transform: translateY(60px);
      // @media (max-width: 991px)
 }      //   transform: none

    p {
      height: 70px;
      overflow: hidden;
      font-weight: 300;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      height: 70px;
      max-width: 350px;
      font-size: 14px;
      // @media (max-width: 991px)
      //   visibility: visible
      //   opacity: 1
 }      //   height: auto


    .btn-gradient {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      // @media (max-width: 991px)
      //   visibility: visible
 } }      //   opacity: 1

  &:hover {
    &::before {
      opacity: 1; }

    .thumbnail {
      img {
        transform: scale(1.1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } }

    .desc {
      .title {
        transform: translateY(0); }

      p {
        opacity: 1;
        visibility: visible; }

      .btn-gradient {
        opacity: 1;
        visibility: visible; } } } }

.card-list {
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s ease-out;
      transform: scale(1.1); } }

  .desc {
    position: absolute;
    width: 100%;
    top: calc(50% + 40px);
    left: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    transition: 0.3s ease-out;
    transform: translateY(0);
    // @media (max-width: 991px)
    //   transform: translateY(calc(-50% - 40px))

    .title {
      font-size: 20px;
      font-weight: 500;
      transition: 0.3s ease-out;
      max-height: 72px;
      overflow: hidden;
      margin-bottom: 15px;
      @media(max-width: 767px) {
        font-size: 18px; } }

    p {
      height: 70px;
      overflow: hidden;
      font-weight: 300;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s ease-out;
      height: 70px;
      max-width: 350px;
      font-size: 14px;
      // @media (max-width: 991px)
      //   visibility: visible
      //   opacity: 1
 }      //   height: auto


    .btn-gradient {
      opacity: 0;
      visibility: hidden;
      // @media (max-width: 991px)
      //   visibility: visible
 } }      //   opacity: 1

  &:hover {
    &::before {
      opacity: 1; }

    .thumbnail {
      img {
        transform: scale(1);
        backface-visibility: hidden;
        transform-origin: 50% 50%; } }


    .desc {
      transform: translateY(calc(-50% - 40px));

      .btn-gradient {
        opacity: 1;
        visibility: visible; } } } }

.card-video {
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
 }    //   opacity: 1

  .thumbnail {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  .link-video-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    top: 0;
    left: 0;
    transition: 0.3s ease-out;
    z-index: 1;
    // @media (max-width: 991px)
    //   opacity: 1

    svg {
      width: 48px;
      height: 48px;
      path {
        fill: #ffffff; } } }

  &:hover {
    &::before, .link-video-detail {
      opacity: 1; } } }

.card-distributor {
  border: 2px solid #E8E7E7;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;

  ._header {
    background-color: #FAFAFA;
    padding: 15px 20px;
    font-weight: 700;
    font-size: 20px;
    @media (max-width: 767px) {
      font-size: 18px; } }

  ._content {
    padding: 20px;

    h4 {
      font-weight: normal;
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: 18px; } } }

  ._location {
    a {
      display: flex;
      color: #D30000;
      text-decoration: none;
      transition: 0.3s ease-in;
      backface-visibility: hidden;
      transform-origin: 0 50%;
      margin-bottom: 5px;

      img {
        width: 15px;
        margin-right: 13px; }

      &:hover {
        transform: scale(1.1); } }

    p {
      padding: 0 28px;
      @media (max-width: 767px) {
        font-size: 14px;
        padding-right: 0; } } }

  ._contact {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      flex-wrap: wrap; }

    img, svg {
      width: 15px;
      margin-right: 10px;
 }      // fill: #25D366

    ._contact-name {
      color: #707070;
      font-weight: 700;
      flex-shrink: 0;
      padding-right: 15px;
      min-width: 120px;
      @media (max-width: 767px) {
        font-size: 14px; } }

    a {
      color: #D30000;
      font-style: italic;
      text-decoration: underline;
      @media (max-width: 767px) {
        font-size: 14px; } } } }

.card-traditional-stores {
  background-color: #FAFAFA;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  margin-bottom: 30px;

  h2 {
    color: #070606;
    margin: 15px 0 0;
    text-transform: uppercase;
    font-weight: 800;
    @media (max-width: 767px) {
      font-size: 24px; } } }

.card-modern-stores {
  background-color: #FAFAFA;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
  img {
    transition: 0.3s ease-in;
    backface-visibility: hidden;
    transform-origin: 50% 50%; }

  &:hover {
    img {
      transform: scale(1.1); } } }

a.card-modern-stores {
  display: block;
  background-color: #EFEFEF; }

.embed-responsive-16by11 {
  &::before {
    padding-top: 65.8%;
    @media (max-width: 991px) {
      padding-top: 75%; } } }

.embed-responsive-16by11\/2 {
  &::before {
    padding-top: 32.43%;
    @media (max-width: 991px) {
      padding-top: 75%; } } }

.list-wrapper {
  margin: 0 -8px;

  > div {
    padding: 0 8px;
    margin-bottom: 16px; } }

.paginations {
  padding: 60px 0 40px;
  @media (max-width: 991px) {
    padding: 30px 0 20px; }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    > li {
      > a, > span {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        font-weight: 500;
        color: #707070;
        text-decoration: none;
        margin: 0 2px; }

      > a {
        &:hover {
          color: #ffffff;
          background-color: #D30000; } }

      .active {
        color: #ffffff;
        background-color: #D30000; } } }

  .pagination-arrow {
    width: auto;
    margin: 0 5px;
    padding: 10px;
    border-radius: 10px;

    span {
      padding: 0 5px;
      @media (max-width: 991px) {
        display: none; } } } }

.btn-filter {
  // border: 2px solid #707070
  border: none;
  box-shadow: inset 0px 0px 0px 2px #707070;
  border-radius: 7px;
  background-color: transparent;
  outline: none!important;
  text-decoration: none!important;
  font-size: 14px;
  padding: 5px 20px;
  display: block;
  text-align: center;
  color: #707070;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  // text-transform: uppercase
  position: relative;
  overflow: hidden;
  > span {
    position: relative;
    z-index: 1; }
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: rgb(246,233,184);
    background: linear-gradient(180deg, rgba(246,233,184,1) 0%, rgba(204,157,51,1) 20%, rgba(232,190,112,1) 80%, rgba(204,157,51,1) 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.3s ease-in-out;
    z-index: 0;
    opacity: 0;
    visibility: hidden; }

  &:hover {
    box-shadow: none;

    &:before {
      opacity: 1;
      visibility: visible; } }

  &.active {
    border-color: #AC1919;
    background-color: #AC1919;
    box-shadow: inset 0px 0px 0px 2px #AC1919;
    color: #ffffff;
    &:before {
      opacity: 0;
      visibility: hidden; } } }

.btn-more {
  background-color: #D30000;
  border-radius: 7px;
  text-decoration: none!important;
  font-size: 14px;
  padding: 10px 20px;
  display: block;
  text-align: center;
  color: #ffffff;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #ad0303; } }

.content-detail-html {
  margin: 0 0 35px;
  padding-bottom: 35px;
  border-bottom: 4px solid rgba(239, 239, 239, 0.5);

  img {
    margin: 20px 0;
    border-radius: 8px;
    width: 100%; }

  h1, h2, h3, h4, h5 {
    color: #D30000;
    font-weight: 500; }

  h1, h2 {
    font-weight: 800; }

  b {
    font-size: 22px;
    margin: 20px 0;
    display: block;
    @media (max-width: 767px) {
      font-size: 18px; } } }

.popular-title {
  font-size: 35px;
  text-align: center;
  font-weight: 800;
  color: #D30000;
  margin-bottom: 30px;
  @media (max-width: 767px) {
    font-size: 30px; } }

.badge-red {
  background-color: #D30000;
  border-radius: 7px;
  font-size: 14px;
  padding: 6px 20px;
  text-align: center;
  color: #ffffff; }

.button-group-wrapper {
  list-style: none;
  padding: 0;
  margin: 0; }

.button-icon {
  display: flex;
  padding: 10px 25px;
  border: 2px solid #E8E7E7;
  margin: 0 auto 25px;
  border-radius: 15px;
  align-items: center;
  color: #7E7E7E;
  text-decoration: none !important;
  max-width: 400px;
  transition: 0.3s ease-in;

  &:hover {
    background-color: #D30303;
    border-color: #D30303;
    color: #ffffff;
    svg {
      fill: #ffffff; } }

  img, svg {
    width: 40px;
    height: 40px;
    fill: #D30303;
    margin-right: 30px;
    transition: 0.3s ease-in;
    @media (max-width: 767px) {
      margin-right: 15px; } }

  p {
    font-size: 20px;
    font-style: italic;
    margin: 0; } }

.form-search {
  position: absolute;
  display: flex;
  background-color:  #AC1919;
  top: 100%;
  right: 0;
  padding: 15px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  min-width: 420px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
  @media (max-width: 1199px) {
    border-radius: 0;
    width: 100%;
    padding: 20px 15px;
    min-width: auto;
    // &::before
    //   content: ''
    //   width: 100%
    //   height: 6px
    //   background: image('footer-gradient.png') repeat
    //   background-size: cover
    //   position: absolute
    //   top: 0
 }    //   left: 0


  input {
    flex: 1;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    padding: 5px 15px;
    outline: none!important; }

  .btn-search {
    border-radius: 20px;
    font-size: 16px;
    padding: 5px 20px;
    background-color: #8e0409;
    outline: none!important;
    text-decoration: none!important;
    border: none;
    color: #ffffff;
    margin-left: 10px;
    display: block;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: auto; }
    &:hover {
      background-color: #800005; } } }

.header-search {
  .fa-search {
    transition: 0.3s ease-in-out; }
  &:hover {
    .fa-search {
      transform: scale(1.07);
      backface-visibility: hidden;
      transform-origin: 50% 50%;
      @media (max-width: 1199px) {
        transform: scale(1); } }
    .form-search {
      opacity: 1;
      visibility: visible;
      @media (max-width: 1199px) {
        opacity: 0;
        visibility: hidden; } } } }

.search-mobile {
  .form-search.show {
    visibility: visible;
    opacity: 1; } }

.form-search-result {
  display: flex;
  padding-top: 15px;
  input {
    flex: 1;
    border-radius: 20px;
    border: 2px solid #707070;
    font-size: 16px;
    padding: 5px 15px;
    outline: none!important; }

  .btn-search {
    border-radius: 20px;
    font-size: 16px;
    padding: 5px 20px;
    background-color: #D30000;
    outline: none!important;
    text-decoration: none!important;
    border: none;
    color: #ffffff;
    margin-left: 10px;
    display: block;
    transition: 0.3s ease-in-out;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767px) {
      min-width: auto; }
    &:hover {
      background-color: #8e0409; } } }

.sitemap-list {
  margin-top: 20px;
  list-style: none;
  padding-left: 0;
  font-size: 18px;
  @media (max-width: 767px) {
    font-size: 16px; }

  li {
    margin-bottom: 20px; }
  p {
    margin-bottom: 20px; }
  a {
    color: #7E7E7E;
    text-decoration: none;
    &:hover {
      color: #E8BE70; } } }

.embed-responsive-item {
  object-fit: cover; }
