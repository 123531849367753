// Video Embed Style
.video-embed {
  padding-top: 60px;
  padding-bottom: 15px;
  width: 100%;

  .embed-responsive {
    border-radius: 8px; }

  .small-container {
    padding: 0 28px; } }
