// FAQ Styles
.panel-faq {
  margin-top: 50px;

  .content {
    padding: 0 10px 20px;
    margin-bottom: 20px;

    &.expand {
      .icon::after {
        opacity: 0; } } }

  .title {
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 2px solid #AC1919;
      margin-right: 24px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      @media (max-width: 767px) {
        margin-right: 10px; }

      &::before {
        content: "";
        width: 14px;
        height: 2px;
        border-radius: 12px;
        background-color: #AC1919;
        display: block; }

      &::after {
        content: "";
        width: 2px;
        height: 14px;
        border-radius: 12px;
        background-color: #AC1919;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: 0.3s ease-in-out; } }

    h4 {
      margin: 0;
      font-size: 20px;
      line-height: 1.3;
      @media (max-width: 767px) {
        font-size: 17px; } } }

  .desc {
    display: none;

    ul {
      list-style: none;
      margin: 0;
      padding-top: 10px;
      @media (max-width: 767px) {
        padding-left: 25px; }

      li {
        line-height: 1.8;
        padding-left: 30px;
        position: relative;

        &::before {
          content: "\2022";
          font-size: 24px;
          line-height: 1.2;
          color: #D30000;
          font-weight: bold;
          width: 1em;
          position: absolute;
          left: 8px; }

        > ul {
          margin: 0;
          padding-left: 0;
          padding-top: 0;
          li {
            @media (max-width: 767px) {
              padding-left: 25px; }

            &::before {
              content: "";
              background-color: #D30000;
              font-weight: bold;
              width: 20px;
              height: 3px;
              border-radius: 2em;
              position: absolute;
              top: 10px;
              left: 0;
              @media (max-width: 767px) {
                width: 14px; } } } } } } } }

