// Popular Styles
.section-popular {
  .btn-more {
    margin: 20px 0;
    @media (max-width: 767px) {
      margin: 0; } }
  .video-wrapper {
    > div {
      @media (max-width: 767px) {
        margin-bottom: 16px; } } } }
