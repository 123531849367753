// Product Categories Style
.product-categories {
  transform: translateY(-50px);
  @media (max-width: 991px) {
    transform: none; }
  &-inner {
    margin: 0;
    > div {
      padding: 0 8px 15px;
      @media (max-width: 767px) {
        padding: 0 0 15px; } }
    .card-category {
      &:before {
        opacity: 0; }
      &:hover {
        &:before {
          opacity: 1; } } } } }
