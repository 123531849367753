// Slider Products Style
.slider-products-wrapper {
  position: relative;
  .slider-products {

    .slide {
      position: relative;
      outline: none;
      @media (max-width: 991px) {
        height: auto;
        min-height: calc(100vh - 80px);
        .container {
          height: auto; } }

      &.no-desc {
        @media (max-width: 991px) {
          .thumbnail {
            height: calc(100vh - 80px); } } }

      .thumbnail {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
        overflow: hidden;
        @media (max-width: 1199px) {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px; }
        @media (max-width: 991px) {
          height: 55vh;
          position: relative;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            @media (max-width: 991px) {
              object-position: 85% 100%; } } } }

      .desc {
        position: absolute;
        z-index: 2;
        padding: 0 28px;
        width: 40%;
        top: 15%;
        max-width: 550px;
        line-height: 1.75;
        @media (max-width: 991px) {
          width: 100%;
          left: 0;
          position: relative;
          top: auto!important;
          padding: 45px 30px;
          max-width: none; }
        @media (max-width: 580px) {
          padding: 30px 0; }

        @media (max-width: 320px) {
          font-size: 13px; }


        .title {
          color: #D30000;
          font-size: 50px;
          font-weight: 900;
          @media (max-width: 1280px) {
            font-size: 42px; }
          @media (max-width: 1199px) {
            font-size: 34px; }
          @media (max-width: 480px) {
            font-size: 28px; }
          @media (max-width: 320px) {
            font-size: 22px; } } } }

    .slick-slide {
      img {
        width: 100%;
        max-height: 100vh;
        object-fit: cover; } } }

  .slider-products-controls {
    position: absolute;
    bottom: 4%;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      bottom: 0;
      z-index: 2; }

    .slick {
      &-arrow {
        border: none;
        text-indent: -999px;
        padding: 0;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        width: 42px;
        height: 42px; }

      &-next {
        background: image('icons/icon-arrow-right.svg') repeat;
        background-size: contain;
        right: -42px;
        order: 1; }
      &-prev {
        background: image('icons/icon-arrow-left.svg') repeat;
        background-size: contain;
        left: -42px; }


      &-dots {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        height: 12px;
        margin: 0;

        button {
          border: none;
          text-indent: -99px;
          padding: 0;
          border: 1px solid #ffffff;
          border-radius: 6px;
          width: 20px;
          height: 6px;
          background-color: transparent;
          margin: 0 3px;
          cursor: pointer;
          outline: none;
          overflow: hidden;
          @media (max-width: 991px) {
            border-color: #D30000;
            background-color: #ffffff; } }

        .slick-active {
          button {
            background-color: #ffffff; } } } } } }
