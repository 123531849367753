// Homepage News Styles
.homepage-news {
  &-inner {
    margin: 0;
    > div {
      padding: 0 8px 15px;
      @media (max-width: 767px) {
        padding: 0 0 15px; } }

    .card-category,
    .card-category:before,
    .thumbnail,
    .thumbnail img {
      border-radius: 8px; }

    .card-category {
      .thumbnail {
        img {
          transform: scale(1.9); } }

      &:hover {
        .thumbnail {
          img {
            transform: scale(2.4); } } } }

    .col-md-12 {
      .card-category {
        .thumbnail {
          img {
            transform: scale(1); } }
        &:hover {
          .thumbnail {
            img {
              transform: scale(1.1); } } } } }

    .card-category {
      @media (max-width: 991px) {
        // min-height: 70vw
        display: flex; }
      .title {
        letter-spacing: 0.05em;
        font-size: 32px;
        @media (max-width: 767px) {
          font-size: 24px; } } } } }
