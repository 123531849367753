// Share Stories Style
.share-stories {
  padding: 50px 0 60px;
  margin-bottom: -50px;
  background-color:#FAFAFA {}
  text-align: center;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column; }

  img {
    margin-bottom: 30px;
    @media (max-width: 767px) {
      max-width: 70px; } }

  h4 {
    font-weight: 300;
    color: #070606;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.5;
    margin-bottom: 20px;
    font-size: 18px;
    @media (max-width: 767px) {
      font-size: 16px; }

    strong {
      font-weight: 500; }

    a {
      color: #D30000;
      text-decoration: none; } } }
