// Filter Styles
.filter-wrapper {
  padding: 30px 0 70px;
  @media (max-width: 767px) {
    padding: 0 0 30px; }

  h3 {
    font-size: 25px;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-align: center;
    margin: 40px auto; }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -5px;

    li {
      margin: 5px; }

    .btn-filter {
      min-width: 170px;
      @media (max-width: 767px) {
        min-width: calc(50vw - 20px); } } } }

