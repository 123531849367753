// Video Styles
.video-wrapper {
  > div {
    margin-bottom: 0; } }

.video-list {
  border: 1px solid #E8E7E7;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 30px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column; }

  .thumbnail {
    width: 60%;
    flex-shrink: 0;
    @media (max-width: 991px) {
      width: 100%; } }

  .desc {
    display: flex;
    flex-direction: column;
    padding: 30px;
    letter-spacing: 0.05em;
    @media (max-width: 991px) {
      padding: 15px; }

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #AC1919; }
    p {
      font-size: 14px;
      font-weight: 300;
      flex: 1; }

    .icon-play {
      align-self: flex-end; } } }

.video-column {
  padding: 0;

  .video-list {
    flex-direction: column; }

  .thumbnail {
    width: 100%; }

  .desc {
    flex-direction: column; }

  p {
    max-width: 85%;
    @media (max-width: 991px) {
      max-width: none; } }

  .icon-play {
    margin-top: -36px;
    @media (max-width: 991px) {
      margin-top: 0; } } }

.video-detail {
  .video-embed {
    padding-top: 40px;
    @media (max-width: 991px) {
      padding-top: 20px; }
    @media (max-width: 767px) {
      padding-top: 0; } } }

.content-video-detail {
  margin-top: 30px;
  text-align: center;
  letter-spacing: 0.1em;

  p {
    margin: 0; }

  h1, h2, h3, h4, h5 {
    color: #070606;
    font-weight: 500; } }

