// Modal Custom Styles
.modal-dialog {
  transform: none!important;
  max-width: 100%;
  margin: 0;
  height: 100%; }

.modal-content {
  border-radius: 0;
  border: none;
  background-color: transparent; }

.button-close-modal {
  position: absolute;
  text-transform: uppercase;
  color: #AC1919;
  font-weight: 700;
  display: flex;
  align-items: center;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  padding: 6px 10px;
  outline: none !important;
  box-shadow: none;
  cursor: pointer;
  z-index: 9;
  top: 30px;
  img {
    margin-right: 6px; } }

.slick-arrow {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  z-index: 9;
  cursor: pointer;
  @media (max-width: 991px) {
    top: 50%; }
  img {
    background-color: #fff;
    border-radius: 50%; }

  &.slick-prev {
    left: 14vw;
    @media (max-width: 991px) {
      left: 30px; } }

  &.slick-next {
    right: 14vw;
    @media (max-width: 991px) {
      right: 30px; } } }

.slider-modal {
  opacity: 0;
  transition: 0.5s;
  .slide {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 30px 0 0;
    align-items: center;
    outline: none;
    background-color: rgba(255,255,255, 0.8);

    .thumbnail {
      height: auto;
      flex: 1;
      @media (max-width: 991px) {
        width: calc(100% - 30px); }
      img {
        object-fit: contain;
        object-position: bottom; } }

    .desc-wrapper {
      height: auto;
      background-color: #EFEFEF;
      width: 100%;
      margin-top: 40px;
      .desc {
        padding: 15px;
        font-size: 18px;
        @media (max-width: 991px) {
          font-size: 16px; } }
      p {
        margin: 0;
        text-align: center;
        color: #070606; } } } }

#modal-foto.show {
  .slider-modal {
    opacity: 1; } }
