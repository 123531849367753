//====================================
// Global Imports
//====================================

// $theme-colors: (
//   "primary": #c4151c
// );
$modal-backdrop-bg: transparent;
$modal-backdrop-opacity: 0;

// Import bootstrap framework
@import 'frameworks/index';

// Import layout  styles
@import 'layout/index';
