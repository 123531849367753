// Hero Wrapper Style
.hero-wrapper {
  position: relative;

  .hero-slider {
    .slide {
      position: relative;
      outline: none;
      @media (max-width: 991px) {
        height: auto;
        min-height: calc(100vh - 80px);
        .container {
          height: auto; } }

      &.no-desc {
        @media (max-width: 991px) {
          .thumbnail {
            height: calc(100vh - 80px); } }

        .desc {
          display: none; } }


      .thumbnail {
        border-bottom-left-radius: 32px;
        border-bottom-right-radius: 32px;
        overflow: hidden;
        @media (max-width: 1199px) {
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px; }
        @media (max-width: 991px) {
          height: 55vh;
          position: relative;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover; } } }

      .desc {
        position: absolute;
        z-index: 2;
        padding: 0 28px;
        width: 40%;
        @media (max-width: 991px) {
          width: 100%;
          left: 0;
          position: relative;
          top: auto!important;
          padding: 45px 30px; }
        @media (max-width: 580px) {
          padding: 30px 0 60px; }

        @media (max-width: 320px) {
          font-size: 13px; }

        .title {
          color: #D30000;
          font-size: 50px;
          font-weight: 500;
          @media (max-width: 1280px) {
            font-size: 42px; }
          @media (max-width: 1199px) {
            font-size: 34px; }
          @media (max-width: 480px) {
            font-size: 28px; }
          @media (max-width: 320px) {
            font-size: 22px; } } }

      &.left-desc {
        .desc {
          top: 23%; }
        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: 80% 100%; } } } }

      &.center-desc {
        text-align: center;
        @media (max-width: 991px) {
          text-align: left; }
        .desc {
          top: 30%;
          left: 50%;
          transform: translateX(-50%);
          a {
            margin: auto;
            @media (max-width: 991px) {
              margin-left: 0; } } }
        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: center center; } } } }

      &.right-desc {
        text-align: right;
        @media (max-width: 991px) {
          text-align: left; }
        .desc {
          top: 23%;
          right: 6%;
          @media (max-width: 1300px) {
            right: 4%; }
          a {
            margin-left: auto;
            @media (max-width: 991px) {
              margin-left: 0; } } }

        .thumbnail {
          img {
            @media (max-width: 991px) {
              object-position: 10% 100%; } } } } }
    .slick-slide {
      img {
        width: 100%;
        max-height: 100vh;
        object-fit: cover; } } }

  .slider-controls {
    position: absolute;
    bottom: 15%;
    display: flex;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 991px) {
      bottom: 25px;
      z-index: 2; }

    .slick {
      &-arrow {
        border: none;
        text-indent: -999px;
        padding: 0;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        width: 42px;
        height: 42px; }

      &-next {
        background: image('icons/icon-arrow-right.svg') repeat;
        background-size: contain;
        right: -42px;
        order: 1; }
      &-prev {
        background: image('icons/icon-arrow-left.svg') repeat;
        background-size: contain;
        left: -42px; }


      &-dots {
        padding: 0;
        list-style: none;
        display: flex;
        height: 12px;
        margin: 0;

        button {
          border: none;
          text-indent: -99px;
          padding: 0;
          border: 1px solid #ffffff;
          border-radius: 6px;
          width: 20px;
          height: 6px;
          background-color: transparent;
          margin: 0 3px;
          cursor: pointer;
          outline: none;
          overflow: hidden;
          @media (max-width: 991px) {
            border-color: #D30000;
            background-color: #ffffff; } }
        .slick-active {
          button {
            background-color: #ffffff;
            @media (max-width: 991px) {
              background-color: #D30000; } } } } } } }
