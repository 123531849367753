// Resep Detail Styles
.resep-detail {
  .resep-overview {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 991px) {
      flex-direction: column; }

    .thumbnail {
      width: 55%;
      flex-shrink: 0;
      border-radius: 8px;
      box-shadow: 4px 4px 3px 0px rgba(0,0,0,0.45);
      @media (max-width: 991px) {
        width: 100%; }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }

    .desc {
      padding: 30px;
      align-items: flex-start;
      @media (max-width: 991px) {
        width: 100%;
        padding: 0;
        padding-top: 30px; }

      .badge-red {
        display: inline-block; }

      h1 {
        font-size: 50px;
        color: #D30000;
        margin: 15px 0;
        font-weight: 800;
        @media (max-width: 1199px) {
          font-size: 35px; }
        @media (max-width: 767px) {
          font-size: 30px; } } } }

  .resep-content {
    display: flex;
    align-items: flex-start;
    border-top: 4px solid #AC1919;
    background-color: #EFEFEF;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-bottom: 30px;
    margin-bottom: 50px;
    @media (max-width: 767px) {
      flex-direction: column; }


    .ingredients {
      background-color: #AC1919;
      color: #ffffff;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 20px 30px 100px 10px;
      min-width: 33%;
      @media (max-width: 991px) {
        min-width: 38%; }
      @media (max-width: 767px) {
        width: 100%;
        padding-bottom: 30px; }


      .title {
        margin-bottom: 20px;
        margin-top: 10px; }

      ul {
        font-size: 18px;
        padding-left: 30px;
        @media (max-width: 1199px) {
          font-size: 16px; } } }

    .steps {
      padding: 20px;


      .step {
        display: flex;
        flex-direction: column;

        label {
          color: #AC1919;
          font-weight: 900; }

        p {
          margin-left: 8%; } } } } }
