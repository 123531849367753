//
// Footer Style
//
.footer {
  position: relative;
  background: #AC1919;
  color: #ffffff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 15px 0;

  &::after {
    content: '';
    width: 100%;
    height: 6px;
    background: image('footer-gradient.png') repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    left: 0; }

  a {
    color: #ffffff; }

  .socmed-links {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    a {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      margin: 5px;

      text-decoration: none;
      display: flex;
      svg {
        fill: rgba(255,255 ,255 ,0.8 ); }
      &:hover {
        svg {
          fill: #E8BE70; } } } }

  .page-links {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: flex;
      align-items: center;
      &::after {
        content: '•';
        font-size: 18px;
        display: inline-block;
        margin: 0 10px;
        color: #ffffff; }
      &:last-child {
        &::after {
          display: none; } } }
    a {
      font-weight: 500;
      color: rgba(255,255 ,255 ,0.8 );
      margin: 5px 0;
      text-decoration: none;
      font-size: 14px;
      letter-spacing: 0.1em;
      &:hover {
        color: #ffffff; } } }

  .copyright {
    text-align: center;
    font-size: 12px;
    @media (max-width: 480px) {
      font-size: 10px; } } }

